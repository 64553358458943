// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const httpServer = {
    // 库存管理查询列表
    getStockTable(params) {
        return postJson("/emp/device-info/tableInventory", params);
    },
    // 库存管理 设备绑定 查询列表
    getStockBindDeviceTable(params) {
        return postJson("/emp/device-info/tableInventoryView", params);
    },
    // 出入库管理
    inoutRecordTable(params) {
        return postJson("/emp/flow/table", params);
    },
    // 出入库详情
    inoutRecordDetails(params) {
        return get("/emp/flow/findFlowViewById", params);
    },
    // 出入库明细列表
    inoutRecordDetailsList(params) {
        return get("/emp/flow-item/findListByFlowId", params);
    },
    // 出入库记录列表
    inoutRecordList(params) {
        return postJson("/emp/flow-item/table", params);
    },
    // 安装信息
    getSetupInfo(id) {
        return get(`/emp/device-info/findById/${id}`);
    },
    // 出入库记录 设备ID
    getDeviceIDList(params) {
        return postJson("/emp/flow/tableByDevice", params);
    },
};

export default httpServer;
