// 库存管理
<template>

    <div class='stockManage baseBg'>

        <!-- 头部搜索 -->
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     label-width="80px"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="设备编号"
                              prop="qrCode"
                              v-show="activeName === '设备ID'">
                    <el-input v-model="searchForm.qrCode"
                              placeholder="请输入设备编号"
                              size="normal"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="SN码"
                              prop="serialNo"
                              v-show="activeName === '设备ID'">
                    <el-input v-model="searchForm.serialNo"
                              placeholder="请输入SN码"
                              size="normal"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="设备类型"
                              prop="typeManagerId">
                    <el-select v-model="searchForm.typeManagerId"
                               placeholder="请选择设备类型"
                               filterable
                               clearable>
                        <el-option v-for="item in empTypeIdOpts"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供应商"
                              prop="provider">
                    <el-select v-model="searchForm.provider"
                               placeholder="请选择供应商"
                               :disabled="!searchForm.typeManagerId"
                               clearable>
                        <el-option v-for="item in providerIdOpts"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="规格型号"
                              prop="empTypeModel">
                    <el-select v-model="searchForm.empTypeModel"
                               placeholder="请选择规格型号"
                               :disabled="!searchForm.typeManagerId"
                               clearable>
                        <el-option v-for="item in empTypeModelOpts"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物料状态"
                              prop="matterStatus"
                              v-show="activeName === '设备ID'">
                    <SelectBomStatus v-model="searchForm.matterStatus"
                                     :empTypeId="null"
                                     placeholder="请选择物料状态"></SelectBomStatus>
                </el-form-item>

                <el-form-item label="检测状态"
                              prop="checkStatus"
                              v-show="activeName === '设备ID'">
                    <SelectCheckStatus v-model="searchForm.checkStatus"
                                       placeholder="请选择检测状态"></SelectCheckStatus>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 表格内容 -->
        <div class="listBox">
            <div class="tableBox">
                <el-tabs v-model="activeName"
                         @tab-click="handleClick">
                    <el-tab-pane label="设备ID"
                                 name="设备ID">
                        <table-device-id :tableData="tableData"></table-device-id>
                    </el-tab-pane>
                    <el-tab-pane label="设备类型"
                                 name="设备类型">
                        <table-company-stock :tableData="tableData"></table-company-stock>
                    </el-tab-pane>
                </el-tabs>
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>

    </div>

</template>

<script>
import SelectBomStatus from "../../components/commonSelect/selectBomStatus";
import SelectCheckStatus from "../../components/commonSelect/selectCheckStatus";
// import CustomerOrProviderSelectTree from "../../components/selectTree/customerOrProviderSelectTree";
import TableDeviceId from "./components/tableDeviceId";
import TableCompanyStock from "./components/tableCompanyStock";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import commonAPI from "@/api/commonAPI.js";
import API from "@/api/stockManage.js";
export default {
    name: "stockManage",

    props: [],

    components: {
        SelectBomStatus,
        SelectCheckStatus,
        // CustomerOrProviderSelectTree,
        TableDeviceId,
        TableCompanyStock,
        Pagination,
    },

    data() {
        return {
            activeName: "设备ID", // tabs切换
            // 筛选表单
            searchForm: {
                qrCode: null,
                matterStatus: "", // 物料状态
                serialNo: null, // SN码
                typeManagerId: "",
                empTypeModel: "", // 规格型号
                checkStatus: "", // 状态
                page: 1,
                size: 20,
            },
            tableData: [], // 列表数据
            empTypeIdOpts: [], // 设备类型下拉
            empTypeModelOpts: [], // 规格型号下拉
            providerIdOpts: [], // 供应商下拉
            pageTotal: 0, // 分页总数
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 获取设备类型下拉数据
        this.getDeviceTypeOpts();
        // 获取列表
        this.getList();
    },

    mounted() {
        // 获取设备类型下拉数据
        this.getDeviceTypeOpts();
        // 获取列表
        this.getList();
    },

    methods: {
        // 供应商的选择
        selectTreeCustClick(node) {
            this.searchForm.provider = node.id;
        },
        //清空客户供应商
        clearProOrCust() {
            this.searchForm.provider = "";
        },
        // 获取设备类型下拉数据
        getDeviceTypeOpts() {
            commonAPI.getDeviceTypeOpts().then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["label"], value: val["code"] };
                });
                this.empTypeIdOpts = opt;
            });
        },
        // 获取规格型号下拉数据
        getTypeModelOpts() {
            commonAPI
                .getTypeModelOpts(this.searchForm.typeManagerId)
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return { label: val["label"], value: val["code"] };
                    });
                    this.empTypeModelOpts = this.empTypeModelOpts.concat(opt);
                });
        },
        // 获取供应商下拉数据
        getProviderIdOpts() {
            commonAPI
                .getProviderIdOpts({
                    typeManagerId: this.searchForm.typeManagerId,
                })
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return { label: val["label"], value: val["code"] };
                    });
                    this.providerIdOpts = opt;
                });
        },
        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.searchForm.page = 1;
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".listBox"),
            });
            if (this.activeName === "设备ID") {
                let data = Object.assign({}, this.searchForm, {
                    haveCode: "YES", // 只显示赋码设备
                });
                API.getStockBindDeviceTable(data)
                    .then((res) => {
                        loading.close();
                        this.tableData = res.content.records;
                        this.pageTotal = res.content.total;
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else if (this.activeName === "设备类型") {
                API.getStockTable(this.searchForm)
                    .then((res) => {
                        loading.close();
                        this.tableData = res.content.records;
                        this.pageTotal = res.content.total;
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
        },
        // 切换tabs
        handleClick() {
            this.resetSearchForm();
        },
        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },
    },

    computed: {},

    watch: {
        "searchForm.typeManagerId": function () {
            // 获取供应商下拉数据
            this.getProviderIdOpts();
            // 获取规格型号下拉数据
            this.getTypeModelOpts();
            if (!this.searchForm.typeManagerId) {
                this.searchForm.provider = "";
                this.searchForm.empTypeModel = "";
            }
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
