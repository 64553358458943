// 导出二维码
<template>

    <div class='exportQRcode'>
        <el-dialog :close-on-click-modal="false"
                   title="导出二维码"
                   :visible.sync="dialogVisible"
                   width="400px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     label-width="100px"
                     :inline="false"
                     size="normal">
                <el-form-item label="二维码编号"
                              prop="qrCode">
                    <span>{{row.qrCode}}</span>
                </el-form-item>

                <!-- 二维码预览 -->
                <div class="qrCodeViewBox">
                    <div class="logo"></div>
                    <el-image v-if="row.qrCode"
                              fit="scale-down"
                              :src="`${PATH}/file/view/?file=${row.qrCodePath}&access-token=${token}`" />
                    <p class="code">{{row.qrCode}}</p>
                </div>

                <el-form-item class="footer">
                    <el-button type="primary"
                               :loading="isLoading"
                               @click="onSubmit">立即导出</el-button>
                    <el-button type="danger"
                               @click="close">取消</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
    </div>

</template>

<script>
export default {
    name: "exportQRcode",

    props: ["row"],

    components: {},

    data() {
        return {
            PATH: window.configPath.baseUrl,
            token: this.commonJs.getCookie("ERPToken"),
            dialogVisible: true,
            form: {
                count: "",
            },
            isLoading: false,
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 导出二维码
        onSubmit() {
            this.isLoading = true;
            let id = this.row.id;
            location.href = `${window.configPath.baseUrl}/emp/device-info/exportQrCode/${id}`;
            this.isLoading = false;
            this.close();
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.qrCodeViewBox {
    border: 1px solid #000;
    width: 244px;
    height: 234px;
    margin: 0 auto;
    .logo {
        margin-left: 12px;
        height: 60px;
        background: url("../../../assets/imgs/qrcodeLogo.png") no-repeat left
            center;
        background-size: auto 42px;
    }
    .el-image {
        width: 120px;
        height: 120px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 5px;
    }
    .code {
        font-size: 18px;
        color: #000;
        text-align: center;
    }
}
</style>
