// 选择设备检测状态
<template>

    <div class="selectEmpType">
        <el-select v-model="checkStatus"
                   :filterable="filterable"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :clearable="clearable"
                   @change="change">
            <el-option v-for="item in options"
                       :value-key="item.value"
                       :key="returnType === 'String' ? item.value : item"
                       :label="item.label"
                       :value="returnType === 'String' ? item.value : item">
            </el-option>
        </el-select>
    </div>

</template>
<script>
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "selectEmpType",
    components: {},

    props: {
        // v-model绑定的值
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "请选择",
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // 返回值类型 ["String", "Object"]
        returnType: {
            type: String,
            default: "String",
        },
    },

    data() {
        return {
            checkStatus: "",
            options: [], // 选项
        };
    },

    created() {},

    mounted() {
        // 获取设备类型下拉数据
        this.getOpts();
    },

    methods: {
        // 获取设备类型下拉数据
        getOpts() {
            commonAPI.getCheckStatus().then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["label"], value: val["code"] };
                });
                this.options = opt;
            });
        },
        // 触发change事件
        change() {
            this.$emit("change");
        },
    },

    computed: {},

    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(newValue) {
                this.checkStatus = newValue;
            },
        },
        checkStatus() {
            this.$emit("input", this.checkStatus);
        },
    },
};
</script>