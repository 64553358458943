// 设备ID table
<template>

    <div class='companyStockTable'>
        <el-table :data="tableData"
                  border
                  :height="tableHeight"
                  stripe>
            <el-table-column prop="qrCode"
                             label="设备编号"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="qrCodePath"
                             label="设备二维码"
                             min-width="100">
                <template slot-scope='scope'>
                    <el-image @click="handleImgClick(`${PATH}/file/view/?file=${scope.row.qrCodePath}&access-token=${token}`)"
                              style="width: 100px; height: 100px"
                              fit="scale-down"
                              :src="`${PATH}/file/view/?file=${scope.row.qrCodePath}&access-token=${token}`"
                              v-if="scope.row.qrCodePath" />
                </template>
            </el-table-column>
            <el-table-column prop="empType"
                             label="设备类型"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="provider"
                             label="供应商"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="empTypeModel"
                             label="规格型号"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="belongDeptName"
                             label="归属部门"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="serialNo"
                             label="SN码"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="matterStatus.text"
                             label="物料状态"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="checkStatus"
                             label="检测状态"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="id"
                             label="操作"
                             min-width="100">
                <template slot-scope="scope">
                    <el-link type="primary"
                             @click="details(scope.row)">查看</el-link>
                    <el-link type="danger"
                             @click="exportQR(scope.row)">导出</el-link>
                </template>
            </el-table-column>
        </el-table>

        <!-- 导出二维码弹框 -->
        <exportQRcode v-if="exportQRcodeVisible"
                      :row="row"
                      @close="closeexportQRcode"></exportQRcode>
        <!-- 查看大图 -->
        <el-image-viewer v-if="showViewer"
                         :on-close="closeViewer"
                         :url-list="viewerImgList" />
    </div>

</template>

<script>
import exportQRcode from "../../stockManage/components/pop_exportQRcode";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
    name: "companyStockTable",

    props: ["tableData"],

    components: {
        exportQRcode,
        ElImageViewer,
    },

    data() {
        return {
            PATH: window.configPath.baseUrl,
            token: this.commonJs.getCookie("ERPToken"),
            showViewer: false, // 预览图片
            viewerImgList: [], // 预览图片列表
            exportQRcodeVisible: false, // 导出二维码弹窗显示
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 导出
        exportQR(item) {
            this.row = item;
            this.exportQRcodeVisible = true;
        },
        // 关闭导出二维码弹框
        closeexportQRcode() {
            this.row = "";
            this.exportQRcodeVisible = false;
        },
        // 点击缩略图的方法
        handleImgClick(url) {
            this.showViewer = true;
            this.viewerImgList = [url];
        },
        // 关闭预览图片
        closeViewer() {
            this.showViewer = false;
            this.viewerImgList = [];
        },
        // 查看采购详情
        details(item) {
            this.$router.push({
                path: "/stockManage/deviceInoutRecord",
                query: { modelId: item.modelId, empId: item.id },
            });
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 300 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
