// 公司库存table
<template>

    <div class='companyStockTable'>
        <el-table :data="tableData"
                  border
                  :height="tableHeight"
                  stripe>
            <el-table-column prop="empType"
                             label="设备类型"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="provider"
                             label="供应商"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="typeModel"
                             label="规格型号"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="empCount"
                             label="设备数量"
                             min-width="100">
            </el-table-column>
            <el-table-column prop="id"
                             label="操作"
                             min-width="100">
                <template slot-scope="scope">
                    <el-link type="primary"
                             @click="details(scope.row)">查看</el-link>
                </template>
            </el-table-column>
        </el-table>

    </div>

</template>

<script>
export default {
    name: "companyStockTable",

    props: ["tableData"],

    components: {},

    data() {
        return {};
    },

    created() {},

    mounted() {},

    methods: {
        // 查看采购详情
        details(item) {
            // 区分赋码和非赋码
            if (
                item.haveCode === null ||
                (item.haveCode && item.haveCode.name === "YES")
            ) {
                // 赋码设备
                this.$router.push({
                    path: "/stockManage/deviceInfoList",
                    query: { modelId: item.modelId },
                });
            } else {
                // 非赋码设备
                this.$router.push({
                    path: "/stockManage/noCodeDeviceDetails",
                    query: {
                        modelId: item.modelId,
                        empTypeModelId: item.empTypeModelId,
                        empId: item.modelId,
                    },
                });
            }
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 300 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
